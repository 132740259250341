import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { RecipeListItem } from '../components/recipe-list-item';

const recipeQuery = graphql`
  query MyQuery {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      nodes {
        frontmatter {
          title
          description
          date(fromNow: true)
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 560, maxHeight: 420) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
      }
    }
    markdownRemark {
      id
    }
  }
`;

const IndexPage: React.FC = () => {
  const data = useStaticQuery(recipeQuery);

  return (
    <Layout>
      <SEO title="Home" />
      <h1 className="text-4xl my-4">Recipes</h1>
      <div>
        {data.allMarkdownRemark.nodes.map((node: any) => (
          <RecipeListItem
            key={node.id}
            title={node.frontmatter.title}
            dateFromNow={node.frontmatter.date}
            description={node.frontmatter.description}
            image={node.frontmatter.featuredImage?.childImageSharp?.fluid}
          />
        ))}
      </div>
    </Layout>
  );
};

export default IndexPage;
