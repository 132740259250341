import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

const convertToSlug = (text: string) =>
  text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export interface RecipeListItemProps {
  title: string;
  dateFromNow: string;
  description: string;
  image?: any;
}

export const RecipeListItem: React.FC<RecipeListItemProps> = ({ image, title, dateFromNow, description }) => {
  return (
    <Link
      to={`/recipe/${convertToSlug(title)}`}
      className="flex flex-col sm:flex-row block border-2 border-black border-solid sm:p-4 hover:border-gray-500 "
    >
      {image ? (
        <Image className="flex-shrink-0 w-full max-h-40 sm:max-w-xs sm:max-h-60" fluid={image} alt={title} />
      ) : null}
      <div className="flex flex-col overflow-hidden px-4 py-2">
        <h2 className="text-lg sm:text-2xl truncate">{title}</h2>
        <p className="text-gray-500">Added {dateFromNow}</p>
        <p className="text-gray-500">{description}</p>
      </div>
    </Link>
  );
};
